.image-modal{

    .modal-content{
        width: 100%;
        height: 100%;
    }
    .modal-body{
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            max-width: 80vw;
            max-height: 75vh;
            object-fit: contain;
            object-position: center;
        }
    }
    @media (min-width: 576px){
        .modal-dialog{
            max-width: max-content;
        }
    }
}