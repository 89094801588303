.club-huston-section{
    width: 100%;
    padding: 100px 0;
    background: url(../../../assets/images/club-huston-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    &::before{
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.4);
        position: absolute;
        z-index: 0;
    }
    .title-con, .section-content{
        position: relative;
        z-index: 2;
    }
}