.age-confirmation-modal{
    
    .modal-content{
        width: 100%;
        height: 100%;
        padding: 50px;
        position: relative;
        background: rgba(255, 255, 255, 0);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(18px);
        border: 1px solid rgba(255, 255, 255, 0.6);
        @media screen and (max-width: 768px) {
            padding: 30px;
        }
        @media screen and (max-width: 568px) {
            padding: 30px 15px;
        }
        h3{
            font-size: clamp(32px, 4vw,62px);
        }
        button{
            width: 50%;
            font-size: clamp(14px, 1.8vw, 26px);
            color: #ffffff;

        }
    }
    .site-logo-con{
        width: 200px;
        margin: 0 auto 40px;
        @media screen and (max-width: 568px) {
            width: 150px;
        }
    }
}

@media screen and (min-width: 768px) {
    .age-confirmation-modal{
        .modal-dialog{
            width: 60vw;
            max-width: 60vw;
        }
    }
}