.coa-page-con{
    // background: #275c53;
    // background-image: url(../../assets/images/bg-pattern.png);
    // background-size: auto;
    // background-position: center;
    .container{
        position: relative;
        z-index: 2;
    }
    .coa-content{
        padding: 50px 0;
        .coa-items{
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            .coa-item{
                width: calc(33.33% - 22px);
                height: 250px;
                position: relative;
                scale: 1;
                transition: all 0.2s;
                background-image: url(../../assets/images/coa-card-bg.png);
                background-size: cover;
                background-position: center;
                border-radius: 20px;
                &:hover{
                    scale: 1.01;
                }
                @media screen and (max-width: 992px) {
                    width: calc(50% - 15px);
                }
                @media screen and (max-width: 568px) {
                    width: 100%;
                    height: 120px;
                }

                // &::before{
                //     content: '';
                //     width: 100%;
                //     height: 100%;
                //     position: absolute;
                //     z-index: 1;
                //     top: 0;
                //     left: 0;
                //     background: rgba(255, 255, 255, 0.1);
                //     border-radius: 16px;
                //     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                //     backdrop-filter: blur(5px);
                //     border: 1px solid rgba(255, 255, 255, 0.6);
                //     // opacity: .8;
                // }
                a, button{
                    padding: 30px;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    justify-content: flex-start;
                    font-size: clamp(18px,1.94vw, 30px);
                    font-weight: 600;
                    text-transform: uppercase;
                    background: transparent;
                    border: 0;
                    text-decoration: none;
                    color: #88ad4c;
                    border-radius: 12px;
                    position: relative;
                    text-align: left;
                    z-index: 2;
                    .aka-text-con{
                        font-size: 22px;
                        text-align: left;
                        padding-left: 26%;
                        .aka-text{
                            font-size: clamp(18px,2.2vw, 42px);
                            font-family: 600;
                        }
                    }
                }
            }
        }
    }
}