
.post-thumbnail{
    border-radius: 16px;
    overflow: hidden;
}
.post-tags{
    display: inline-flex;
    gap: 8px;
    flex-wrap: wrap;
    .post-tag{
        font-size: 12px;
        line-height: 1.3em;
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: 2px;
        padding: 8px 25px 8px;
        border-radius: 16px;
        background-color: #e2bb53;
        color: #275c53;
        font-weight: 500;
    }
}
.post-data{
    h5{
        font-weight: 600;
        margin-top: 12px;
        margin-bottom: 8px;
    }
    p{
        margin-bottom: 8px;
        font-size: clamp( 16px, 1.25vw, 18px);
        font-family: 'Jost', sans-serif;
        font-weight: normal;
    }
    .share-btns{
        a{
            font-size: 25px;
            &:hover{
                color: #e2bb53;
            }
        }
    }
}