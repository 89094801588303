.offer-subscription-section{
    padding: 80px 0;
    background-color: #275c53;
    margin: 80px 0 30px;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        zoom: 1;
        opacity: .2;
        background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251609546065');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @media screen and (max-width: 1920px){
            background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251611263317');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        @media screen and (max-width: 1080px){
            background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251612136741');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        @media screen and (max-width: 768px){
            background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251612452520');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        @media screen and (max-width: 568px){
            background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251613368960');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    @media screen and (max-width: 568px){
        margin: 50px 0 0;
    }
    .section-content{
        position: relative;
        z-index: 2;
    }
}