.footer{
    background: #f5f0ea;
    .footer-content{
        padding: 50px 0 70px;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        @media screen and (max-width: 568px) {
            padding: 40px 10px;
        }
        .footer-item{
            width: calc(33.33% - 23px);
            @media screen and (max-width: 1080px) {
                width: calc(33.33% - 22px);
                &:last-child{
                    width: 100%;
                }
            }
            @media screen and (max-width: 768px) {
                width: calc(50% - 22px);
                &:nth-of-type(3){
                    width: 100%;
                }
            }
            @media screen and (max-width: 568px) {
                width: 100%;
            }
            p{
                font-size: clamp( 10px, 0.97vw, 14px);
                text-align: justify;
            }
            .item-title{
                padding-bottom: 20px;
                .title-icon{
                    width: 24px;
                    display: inline-block;
                    margin-right: 10px;
                    img{
                        width: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }
                h3{
                    padding: 0;
                    margin: 0;
                    font-size: 24px;
                    line-height: 1.2em;
                    text-align: left;
                    font-weight: 400;
                    color: #275c53;
                }
            }
            .item-links{
                display: flex;

                .navbar{
                    list-style: none;
                    width: 50%;
                    height: max-content;
                    .nav-item{
                        width: 100%;
                        margin-bottom: 8px;
                        .nav-link{
                            font-weight: 400;
                            letter-spacing: 0;
                            font-size: 16px;
                            color: #565656;
                            position: relative;
                            cursor: pointer;
                            &::after{
                                content: "+" !important;
                                display: inline-block;
                                -webkit-font-smoothing: antialiased;
                                font-style: normal;
                                font-family: 'Font Awesome 5 Free';
                                font-variant: normal;
                                text-rendering: auto;
                                font-weight: 900;
                                color: #e2bb53;
                                font-size: 16px;
                                margin-left: 6px;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
            .opening-hours{
                span{
                    color: #565656;
                    font-size: 16px;
                    line-height: 2.4em;
                    white-space: nowrap;
                }
                .divider-border{
                    width: 100%;
                    height: 0px;
                    border-bottom: 1px dotted #565656;
                    margin: 0 5px;
                }
            }
            .subscription-form-con{
                p{
                    color: rgba(0,0,0,.65);
                    font-size: 16px;
                    font-weight: 300;;
                    line-height: 1.6em;
                    margin-bottom: 20px;
                }
                .form-control{
                    width: 100%;
                    display: block;
                    margin: 0;
                    background: #fff;
                    font-size: 18px;
                    padding: 19px 30px;
                    color: #000;
                    border: 0;
                    background-clip: padding-box;
                    box-shadow: none !important;
                    font-weight: 400;
                }
                .btn{
                    padding: 18px 30px;
                    min-width: 240px;
                    margin-top: 20px;
                    &:hover{
                        background: #275c53;
                    }
                    @media screen and (max-width: 568px) {
                        padding: 10px 20px;
                        min-width: 100%;
                    }
                }
            }
        }
    }
    .copyrights {
        position: relative;
        padding: 36px 0;
        overflow: hidden;
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251602577569');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            zoom: 1;
            opacity: .2;
            @media screen and (max-width: 1080px) {
                background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251607283314');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            @media screen and (max-width: 567px) {
                background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251608245259');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        p{
            position: relative;
            z-index: 2;
            margin-bottom: 0;
            font-size: 14px;
            position: relative;
            @media screen and (max-width: 568px) {
                text-align: center;
            }
        }

        @media screen and (max-width: 568px) {
            padding: 20px 0;
        }
    }
}