.cart-page{
    .page-content{
        padding-top: 100px;
        padding-bottom: 120px;
        .empty-cart-message{
            padding: 18px 36px 18px 30px;
            margin-bottom: 32px;
            position: relative;
            background-color: #f7f6f7;
            color: #515151;
            border-top: 3px solid #e2bb53;
            word-wrap: break-word;
            border-radius: 16px;
        }

        .cart-items-table{
            width: 100%;
            margin-bottom: 32px;
            border-radius: 16px 16px 0 0;
            overflow: hidden;

            .table-head{
                padding: 20px 25px;
                height: 70px;
                vertical-align: middle;
                background-color: #f5f0ea;
                color: #565656;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 1.5em;
                text-transform: none;
                text-align: center;
            }
            .table-data{
                padding: 20px 25px;
                height: calc(100% - 70px);
                vertical-align: middle;
                background-color: #fff;
                color: #565656;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 1.5em;
                text-transform: none;
                text-align: center;
                border: 1px solid rgba(0,0,0,.1);
                border-top: 0;

                .product{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    .product-img{
                        width: 60px;
                        height: 60px;
                        border-radius: 10px;
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }
                .product-quantity{
                    width: 80%;
                    height: 56px;
                    max-width: 150px;
                    margin: 0 auto;
                }
            }
            .table-row{
                .table-data{
                    &:first-child{
                        width: 65px;
                        padding: 15px;
                        .table-head{
                            border-radius: 16px 0 0 0;
                        }
                        .remove-icon{
                            color: #e65338;
                            font-size: 32px;
                            line-height: 15px;
                            width: 16px;
                            height: 16px;
                            display: block;
                            text-align: center;
                            transition: all .2s ease;
                            font-weight: 400;
                            cursor: pointer;
                            margin: 0;
                        }
                    }
                }
                &:nth-of-type(2){
                    .table-data{
                        justify-content: flex-start;
                    }
                }
            }

            @media screen and (max-width: 992px) {
                table{
                    display: flex;
                }
                thead{
                    display: none;
                }
                tbody{
                    width: 100%;
                } 
                .table-row{
                    width: 100%;
                    display: block;
                    margin-bottom: 10px;
                    border-bottom: 2px solid rgba(0,0,0,.1);
                }
                .table-data{
                    text-align: right;
                    height: auto;
                    border: 0;
                    display: flex;
                    justify-content: space-between !important;
                    .product .product-img{
                        display: none;
                    }
                    .product-quantity{
                        margin: 0;
                        margin-left: auto;
                        height: 40px;
                    }
                    &::before{
                        content: attr(data-title) ": ";
                        font-weight: 700;
                    }
                    &.remove-icon-con{
                        width: 100% !important;
                        justify-content: flex-end !important;
                        &::before{
                            display: none;
                        }
                    }
                }
                
            }
        }

        .cart-bill-deails{
            margin-top: 50px;
            .bill-details-table{
                max-width: 350px;
                margin-left: auto;
                .table{
                    margin-bottom: 30px;
                    th, td{
                        font-size: 18px;
                    }
                }
                .table-head{
                    width: 35%;
                    text-align: end;
                    font-weight: 700;
                    color: #275c53;
                    border-right-width: 1px;
                    padding: 15px 20px;
                }
                .table-data{
                    width: 70%;
                    color: #565656;
                    padding: 15px 20px;
                    text-align: end;
                }

                .checkout-btn{
                    font-size: 16px;
                }
            }
        }
    }
}