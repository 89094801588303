.blog-post{
    width: calc(50% - 15px);
    @media screen and (max-width: 992px) {
        width: calc(33.33% - 22px);
    }
    @media screen and (max-width: 768px) {
        width: calc(50% - 15px);
    }
    @media screen and (max-width: 568px) {
        width: 100%;
    }
    .post-img{
        width: 100%;
        height: 280px;
        overflow: hidden;
        display: block;
        position: relative;
        z-index: 1;
        border-radius: 16px;
        background-clip: border-box;
        .image-con{
            width: 100%;
            height: 100%;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .post-tags{
            position: absolute;
            z-index: 2;
            top: 22px;
            left: 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            .post-tag{
                padding: 5px 15px;
                background: #e2bb53;
                border-radius: 30px;
                text-transform: capitalize;
                pointer-events: none;
            }
        }
    }
    a{
        text-decoration: none;
    }
    .post-title{
        color: #275c53;
        font-size: 24px;
        margin: 16px 0;
        cursor: pointer;
    }
    .post-details{
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        row-gap: 10px;
        i{
            color: #e2bb53;
        }
        p{
            color: #565656;
        }
         
    }
}