.payment-methods{
    margin-top: 50px;
    .payment-method{
        margin-bottom: 20px;
        .method-option{
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 16px;
            font-weight: 500;
            color: #565656;
            input{
                accent-color: #275c53;
                cursor: pointer;
            }
            label{
                cursor: pointer;
            }
        }
        p{
            margin: 10px 0 0;
            padding-left: 23px;
        }
        .payment-description {
            opacity: 0;
            height: 0;
            overflow: hidden;
            transition: opacity 0.3s, height 0.3s;
        }
        
        .payment-description.visible {
            opacity: 1;
            height: auto;
        }
          
    }
}