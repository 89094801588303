.breadcrumbs{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 15px;
    .nav-link{
        display: inline-block;
        text-transform: uppercase;
        font-size: 12px;
        @media screen and (max-width: 568px) {
            font-size: 9px;

        }
    }
}