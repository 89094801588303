.product-card{
    padding: 10px 15px 25px;
    position: relative;
    .sale-tag{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: absolute;
        z-index: 2;
        background: #275c53;
        color: #FFF;
        top: 10px;
        right: 10px;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }
    .product-img{
        width: 100%;
        height: 320px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            cursor: pointer;
        }
    }
    .product-details{
        height: calc(100% - 325px);
    }
    .product-title{
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
        color: #275c53;
    }
    .product-rating{
        display: flex;
        gap: 6px;
        font-size: 13px;
        align-items: center;
        justify-content: center;
        color: #e2bb53;
    }
    .product-description{
        margin: 14px 0 20px;
    }
    .product-price{
        font-size: 24px;
        color: #275c53;
        font-weight: 400;
        letter-spacing: 0;
        text-align: center;
        margin-bottom: 7px;
        &.on-sale{
            text-decoration: line-through;
            text-decoration-color: #565656;
            text-decoration-thickness: 2px;
        }
        &.sale-price{
            color: #ea4a34;
        }
    }
    .product-btns-con{
        display: flex;
        gap: 2px;
        .btn{
            padding: 10px 24px;
            line-height: 1.6em;
            font-size: 12px;
        }
    }
    .btn-add-to-cart{
        margin-top: 14px;
        min-width: 90px;
        border-radius: 0 50px 50px 0;
    }
    .btn-coa{
        margin-top: 14px;
        padding: 10px 24px;
        border-radius: 50px 0 0 50px;
    }
}