.contact-page{
    .contact-content-con{
        padding: 70px 0 0;
        position: relative;
        z-index: 2;
        @media screen and (max-width: 568px) {
            padding: 40px 0;
        }
        .contact-form-con{
            margin: 40px auto;
            width: 95%;
            max-width: 850px;
            padding: 60px;
            border-radius: 20px;
            background: #f5f0ea;
            
            @media screen and (max-width: 568px) {
                padding: 30px;
            }

            .form-control{
                border: 0;
                border-radius: 0;
                height: 60px;
                font-size: 18px;
                border-bottom: 2px solid #275c53;
                background: transparent;
                padding: 24px 25px 14px 0;
                &.form-textarea{
                    height: 100px;
                }
                &.invalid{
                    border-bottom: 2px solid red;
                }
                @media screen and (max-width: 568px) {
                    height: 40px;
                }
            }
        }
    }
    .map-wrapper{
        background: #fff;
        width: 100%;
        height: auto;
        margin-top: 0;
        position: relative;
        z-index: 2;
        .map-container{
            z-index: 1 !important;
        }
    }
}
.leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
}