.my-account-page{
    .account-content{
        padding: 60px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        .account-sidebar{
            background-color: #f5f0ea;
            padding: 56px 20px;
            margin-top: 0;
            margin-bottom: 60px;
            border-radius: 16px;
            background-clip: border-box;
            width: calc(30% - 15px);
            .navbar-nav{
                .nav-item{
                    margin-bottom: 0;
                    padding: 0;
                    border-radius: 8px;
                    &:hover{
                        background: #275c53;
                        .nav-link{
                            color: #fff;
                        }
                    }
                }
                .nav-link{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    font-size: 18px;
                    color: #275c53;
                    font-weight: 500;
                    padding: 14px 20px;
                    i{
                        font-size: 20px;
                    }
                }
            }
            @media screen and (max-width: 992px) {
                width: 100%;
                padding: 20px;
                position: relative;
                .toggle-con{
                    font-size: 22px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    justify-content: space-between;
                    height: 40px;

                }
                .navbar-nav{
                    display: none;
                    margin-top: 20px;
                    &.show{
                        display: block;
                    }
                }
            }
        }
        .account-main-content{
            width: calc(70% - 15px);
            @media screen and (max-width:768px) {
                width: 100%;
            }
        }
    }
}