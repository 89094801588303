.ReactFlagsSelect-module_selectBtn__19wW7{
    width: 100%;
    height: 50px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #565656;
    appearance: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5c5c5;
    border-radius: 50rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}