.product-details-page{
    padding-bottom: 60px;
    .page-content{
        padding: 100px 0 40px;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        @media screen and (max-width: 568px) {
            padding: 50px 10px 40px;
        }
        .product-img{
            width: calc(40% - 15px);
            min-height: 400px;
            border-radius: 16px;
            overflow: hidden;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            img{
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .product-details{
            width: calc(60% - 15px);
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            .product-rating{
                display: flex;
                align-items: center;
                gap: 10px;
                color: #e2bb53;
                margin-bottom: 20px;
            }
            .product-actions{
                display: flex;
                align-items: center;
                margin: 30px 0;
                gap: 20px;
                .product-quantity{
                    width: 100px;
                    height: 56px;
                    .form-control{
                        height: 100%;
                    }
                }
            }
        }
    }

    .related-products{
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        .product-card{
            width: calc(33.33% - 22px);
            @media screen and (max-width: 768px) {
                width: calc(55% - 15px);
            }
            @media screen and (max-width: 568px) {
                width: 100%;
            }
        }
    }
}