.login-page-con{
    .login-content{
        padding: 80px 0;
        max-width: 750px;

        .login-card{
            border: 1px solid #d3ced2;
            padding: 30px;
            margin: 2em 0;
            text-align: left;
            border-radius: 5px;

            .form-control{
                border: 0;
                border-radius: 0;
                height: 60px;
                font-size: 20px;
                border-bottom: 2px solid #275c53;
                padding: 24px 25px 14px 0;
                &[type='password']{
                    font-size: 50px;
                }
                &.invalid{
                    border-color: red;
                }
            }

            .form-actions-con{
                display: flex;
                align-items: center;
                gap: 20px;
                .btn-submit{
                    width: 200px;
                }
            }
            .errorMessage{
                min-height: 25px;
                display: block;
            }
        }
    }
}