.product-categories{
    padding: 60px 0;
    .categories-con{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .product-category{
            width: calc(20% - 25px);
            margin-top: 60px;
            cursor: pointer;
            
            a{
                text-decoration: none;
            }
            .category-img{
                text-align: center;
                img{
                    width: 70%;
                    height: auto;
                    object-fit: contain;
                    object-position: center;
                }
            }
            .category-title{
                margin-top: 30px;
                h4{
                    font-size: 24px;
                    color: #275c53;
                    text-align: center;
                    font-weight: 500;
                    @media screen and (max-width: 568px) {
                        font-size: 18px;
                    }
                }
            }

            @media screen and (max-width: 768px) {
                width: calc(33.33% - 14px);
            }
            @media screen and (max-width: 768px) {
                width: calc(50% - 10px);
            }
            @media screen and (max-width: 380px) {
                width: 100%; 
            }
        }
    }
}