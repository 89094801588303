.applicaiton-page{
    // background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251619345204');
    // background-size: cover;
    // background-attachment: fixed !important;
    // transition: background 0s !important;
    // background-position: 50% 28px;
    padding-bottom: 60px;
    position: relative;
    &::before{
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;
        background-color: #2A7D58;
        background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251619345204');
        background-size: cover;
        background-position: right top;
        @media screen and (max-width: 568px){
            background-color: #2A7D58;
            background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401271651141893');
            background-size: cover;
            background-position: top right;
            background-repeat: no-repeat;
        }
    }

    .page-content{
        padding: 60px 30px;
        margin: 60px auto 0;
        position: relative;
        z-index: 1;
        &::before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(18px);
            border: 1px solid rgba(255, 255, 255, 0.6);
        }
        
        .application-purpose-details{
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            margin-bottom: 40px;
            position: relative;
            .purpose-item{
                width: calc(33.33% - 22px);
                .form-label{
                    cursor: pointer;
                }
                @media screen and (max-width:768px) {
                    width: 100%;
                }
            }
        }

        .applicant-details{
            z-index: 2;
            position: relative;
            .btn-submit{
                width: 100%;
                font-size: 18px;
                &:hover{
                    color: #fff;
                }
            }
        }
        .form-label{
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            text-transform: none;
            @media screen and (max-width:768px) {
                font-size: 18px;
            }
        }
        .ReactFlagsSelect-module_selectBtn__19wW7{
            background: rgba(255,255,255,0.1);
            color: #fff;
        }
        .form-control{
            height: 50px;
            border: 1px solid #c5c5c5;
            padding: 8px 20px;
            background: rgba(255,255,255, 0.2);
            color: #fff;
            font-size: 20px;
            &:focus, &:active{
                box-shadow: none;
                outline: none;
            }
            &::placeholder{
                color: #c5c5c5;
            }
            option{
                color: #000;
            }
            &.invalid {
                border: 1px solid red;
            }
        }
        .region-control,  .clubLocSelect-control{
            border-radius: 70px;
            &.invalid .form-control{
                border: 1px solid red;
            }
        }
    }
}