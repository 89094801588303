.menu-page{
    .container{
        position: relative;
        z-index: 2;
    }
    .page-content-con{
        padding: 7vh 0;
        .menu-items{
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            .menu-item{
                width: calc(33.33% - 22px);
                height: 300px;
                position: relative;
                overflow: hidden;
                border-radius: 12px;
                z-index: 1;
                cursor: pointer;

                @media screen and (max-width: 768px) {
                    width: calc(50% - 15px);
                }
                @media screen and (max-width: 568px) {
                    width: 100%;
                }

                img{
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    
                }
                .item-title{
                    width: 100%;
                    position: absolute;
                    padding: 15px 12px;
                    background: #275c53e1;
                    color: #fff;
                    z-index: 2;
                    bottom: 0;
                    left: 0;
                    text-align: center;
                    text-transform: capitalize;
                }
            }
        }
    }
}