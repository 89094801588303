@import './variables';
@import './fonts';
@import './typography';
@import './flags';

*{
    font-family: 'Jost', sans-serif;
    box-sizing: border-box;
}

// Containers 
.container{
    @media (min-width: 1600px) {
      max-width: 1570px;
    }
    @media (max-width: 1440px) {
      max-width: 1280px;
    }
    @media (max-width: 1366px) {
      width: 95%;
    }
    @media (max-width: 568px) {
      width: 93%;
    }
}

// Text Colors
.text-theme{
  &-primary{
    color: $primary;
  }
  &-secondary{
    color: $secondary;
  }
  &-grey{
    color: $textGrey;
  }
}

// Backgrounds Colors
.bg-theme{
  &-primary{
    background-color: $primary;
  }
  &-secondary{
    background-color: $secondary;
  }
}

// Buttons
.btn{
  padding: 18px 30px 18px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 568px) {
    padding: 10px 30px;
  }
  &-primary{
    background: $primary;
    border: 1px solid $primary;
    color: #fff;
    &:hover, &:focus, &:active{
      background: $primary !important;
      border: 1px solid $secondary !important;
      color: #565656;
      &::after{
        background: $secondary;
      }
    }
    &:disabled{
      background: #3c7e73;
      border: 0;
    }
  }
  &-secondary{
    background: $secondary;
    border: 1px solid $secondary;
    color: $textGrey;
    &:hover, &:focus, &:active{
      background: $secondary !important;
      border: 1px solid $primary !important;
      color: #fff;
      &::after{
        background: $primary;
      }
    }
    &:disabled{
      background: #e2bb53;
      border: 0;
    }
  }
  &-outline-secondary{
    border: 1px solid $secondary;
    background: transparent;
  }
}

.hover-btn{
  position: relative;
  overflow: hidden;
  span{
    position: relative;
    z-index: 2;
  }
  &:after{
    content: "";   
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 102%;
    z-index: 0;
    transition: all 0.2s;
  }
  &:hover{
    &::after{
      top: 0;
    }
  }
}

.hover-link{
  position: relative;
  display: inline-block;
  padding-bottom: 3px;
  cursor: pointer;
  &::after{
    content: '';
    width: 0;
    height: 2px;
    background-color: $secondary;
    bottom: 0;
    left: 0;
    position: absolute;
    transition: all 0.3s;
  }
  &:hover{
    &::after{
      width: 100%;
    }
  }
}
.section-title{
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  margin-bottom: 10px;
  color: $primary;
  line-height: 1.4em;
  margin: 0;
  position: relative;
  display: inline;
}

.text-border{
  &-right::after{
    content: "";
    height:  2px;
    background: $secondary;
    width: 40px;
    display: inline-block;
    margin-bottom: 3px;
    margin-left: 15px;
    position: relative;
  }
  &-bottom::after{
    content: "";
    position: absolute;
    height:  2px;
    background: $secondary;
    border-color: var(--second);
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.text-red{
  color: #FF0000;
}
.form-label{
  font-family: 'Jost',serif;
  color: $textGrey;
  font-weight: 400;
  font-size: 18px;
  line-height: 2;
  display: block;
  margin-bottom: 2px;
  @media screen and (max-width:768px) {
    line-height: 1.5;
    margin-bottom: 5px;
    font-size: 16px;
  }
}
.ReactFlagsSelect-module_selectBtn__19wW7{
  background: #fff;
  color: #000;
}
.form-control{
  height: 50px;
  border: 1px solid #c5c5c5;
  padding: 8px 20px;
  background: #fff;
  color: #000;
  font-size: 18px;
  &:focus, &:active{
    box-shadow: none;
    outline: none;
  }
  &::placeholder{
    color: #a2a2a2;
  }
  &[type='password']{
    color: #000;
    font-size: 50px;
    line-height: 1;
    vertical-align: middle;
    padding: 9.5px 20px 0;
    letter-spacing: -5px;
  }
}
textarea.form-control{
  height: 100px;
  border-radius: 16px;
}
input[type="radio"], input[type="checkbox"]{
  accent-color: $primary;
}
.file-input.invalid + .custom-file-input{
  border: 1px solid red;
}
.custom-file-input{
  width: 100%;
  height: 150px;
  border: 1px solid #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i{
      font-size: 40px;
      color: #fff;
  }
  .file-name{
      color: #fff;
      font-size: 18px;
  }

}

.password-control-con{
  position: relative;
  .show-pass-btn{
      font-size: 18px;
      position: absolute;
      bottom: 10px;
      right: 30px;
      cursor: pointer;
      color: #275c53;
      .bi-eye-slash-fill{
          color: #e2bb53;
      }

      &.register-show-pass-btn{
        bottom: 30%;
      }
    @media screen and (max-width: 568px) {
      right: 10px;
    }
  }
}
.react-pdf__Page__canvas{
  width: 100% !important;
}
.cursor-pointer{
  cursor: pointer;
}

.bg-wrapper{
  position: relative;
  &::before{
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: -1;
      background: url('https://thcclub.s3.amazonaws.com/static-data/page-backgrounds/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251635103252');
      background-size: cover;
      background-position: right top;
       @media screen and (max-width: 1920px){
          background: url('https://thcclub.s3.amazonaws.com/static-data/page-backgrounds/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251636147695');
          background-size: 100% 100vh;
          background-position: right top;
      }
      @media screen and (max-width: 768px){
          background: url('https://thcclub.s3.amazonaws.com/static-data/page-backgrounds/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401261717439424');
          background-size: cover;
          background-position: top right;
          background-repeat: no-repeat;
      }
  }
  &::after{
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.75);
      z-index: -1;
  }
}