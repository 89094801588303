.checkout-page{
    .page-content{
        padding-top: 100px;
        padding-bottom: 120px;
        .page-info-bar{
            padding: 18px 36px 18px 30px;
            margin-bottom: 32px;
            position: relative;
            background-color: #f7f6f7;
            border-top: 3px solid #e2bb53;
            word-wrap: break-word;
            border-radius: 16px;
            p, button{
                color: #515151;
                font-size: 18px;
                text-transform: none;
                letter-spacing: 0;
                padding: 0;
                font-weight: normal;
            }
            .btn:hover{
                color: #e2bb53;
            }
        }

        .coupon-section{
            width: 70%;
            margin: 30px auto;
            max-width: 600px;
            padding: 30px 20px;
            border-radius: 8px;
            border: 1px solid #00000033;
            .coupon-input{
                border: 0;
                border-bottom: 2px solid #275c53;
                border-radius: 0;
                margin: 20px 0;
            }
            @media screen and (max-width: 568px) {
                width: 100%;
            }
        }

        .checkout-section{
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            margin-top: 60px;

            .checkout-item{
                width: calc(50% - 20px);
                @media screen and (max-width: 992px) {
                    width: 100%;
                }
            }

            .checkout-form-con{
                margin-top: 40px;
            }
            .bill-details-con{
                .bill-table{
                    width: 100%;
                    border-top: 1px solid #565656;
                    border-bottom: 2px solid #565656;
                    border-collapse: collapse !important;
                    margin: 20px 0 10px;
                    td{
                        padding: 15px 30px;
                        vertical-align: middle;
                        font-size: 16px;
                        color: #565656;
                        font-weight: 500;
                        &:nth-of-type(1){
                            width: 70%;
                        }
                        &:nth-of-type(2){
                            width: 30%;
                        }
                        .quantity{
                            font-weight: 700;
                        }
                        @media screen and (max-width:568px) {
                            padding: 15px 10px;
                        }
                    }
                }
                .total-bill-table{
                    .bill-row{
                        display: flex;
                        align-items: center;
                        span{
                            padding: 10px 30px;
                            font-size: 16px;
                            color: #565656;
                            font-weight: 500;
                            &:nth-of-type(1){
                                width: 70%;
                                font-weight: 700;
                                text-align: right;
                                color: #275c53;
                            }
                            &:nth-of-type(2){
                                width: 30%;
                            }
                        }
                    }
                }
            }
        }

    }
}