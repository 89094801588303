.about-section{
    .section-content{
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 30px;
        .section-item{
            width: calc(33.33% - 20px);
            @media screen and (min-width: 768px) {
                &.w-md-66{
                    width: calc(66.66% - 15px);
                }
                &.w-md-33{
                    width: calc(33.33% - 15px);
                }
            }
            @media screen and (max-width: 992px) {
                width: calc(50% - 15px);
                &:nth-of-type(3){
                    width: 100%;
                }
            }
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        .section-text-con{
            padding: 10px 20px 10px 10px;
            .section-title{
                margin-bottom: 10px;
            }
            h3{
                margin-bottom: 16px;
            }

            .btn-readmore{
                margin: 50px 0 40px;
                width: 240px;
                height: 63px;
                @media screen and (max-width: 568px) {
                    width: 180px;
                    height: 50px;
                    margin: 30px 0 20px;
                }
            }
        }
        .section-img{
            border-radius: 16px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .section-opening-hours{
            padding: 50px;
            h4{
                margin-bottom: 30px;
            }
            .opening-hours-item{
                text-align: left;
                padding-bottom: 6px;
                margin-bottom: 6px;
                font-size: 16px;
                font-weight: 400;
                letter-spacing:0;
                border-bottom: 1px solid rgba(255,255,255,.1);
            }
        }
        .section-img-text{
            width: calc(66.66% - 15px);
            padding: 80px 50px;
            background: url(../../../assets/images/about-2.jpg);
            background-size: cover;
            background-position: center right;
            background-repeat: no-repeat;
            @media screen and (max-width: 768px) {
                width: 100%;
                padding: 50px;
            }

            h3{
                margin: 36px 0 24px;
            }
            .btn-readmore{
                margin-top: 16px;
            }
        }

        .section-glass-layer{
            position: relative;
            background-color: rgba(39,92,83,67%);
            backdrop-filter: blur(106px);
            box-shadow: 0 0 10px rgba(0,0,0,20%) ;
        }
        .section-no-img-text{
            // width: calc(66.66% - 15px);
            padding: 80px 50px;
            background-image: linear-gradient(144deg,#e2bb53,#275c53);
            // background: url(../../../assets/images/about-2.jpg);
            // background-size: cover;
            // background-position: center right;
            // background-repeat: no-repeat;
            .align-text-center{
                text-align: center;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                padding: 50px;
            }

            h3{
                margin: 36px 0 24px;
            }
            .btn-readmore{
                margin-top: 16px;
            }
        }

        .section-item.item-5, .section-item.item-6{
            width: calc(33.33% - 15px);
            @media screen and (max-width: 768px) {
                width: calc(50% - 15px);
            }
            @media screen and (max-width: 568px) {
                width: 100%;
            }
        }
        .section-item.item-6{
            padding: 50px;
            background: url(../../../assets/images/about_05.jpg);
            background-size: cover;
            background-position: bottom right;
            background-repeat: no-repeat;
            h3{
                margin: 0 0 48px;
            }
            .btn-readmore{
                width: 120px;
                height: 36px;
                padding: 0;
                font-size: 10px;
            }
        }
        .item-7{
            background: url(../../../assets/images/about_04.jpg);
            background-size: cover;
            background-position: top right;
            background-repeat: no-repeat;
            position: relative;
            padding: 50px 105px 50px 50px;
            display: flex;
            justify-content: flex-end;
            overflow: hidden;
            &::before{
                content: "";
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,.35);
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
            }
            .item-content{
                position: relative;
                z-index: 2;
                display: inline-block;
                p{
                    max-width: 347px;
                }
                .btn-readmore{
                    margin-top: 50px;
                }
            }

            @media screen and (max-width: 568px) {
                padding: 50px;
            }
        }
    }
}