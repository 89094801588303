h1, h2, h3, h4, h5, h6, p{
    margin: 0;
}
h2{
    font-size: 128px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 125%;
    font-size: clamp(40px, 8.8vw, 128px);
}

h3{
    font-size: clamp(26px, 3.33vw, 48px);
    font-weight: 400;
    letter-spacing: 0;
    word-wrap: break-word;
    line-height: 125%;
}
h4{
    font-weight: 400;
    letter-spacing:0;
    word-wrap: break-word;
    font-size: clamp(22px, 2.5vw, 36px);
    line-height: 125%;
}
h5{
    font-weight: 400;
    letter-spacing:0;
    word-wrap: break-word;
    font-size: clamp( 20px, 1.52vw, 22px);
    line-height: 125%;
}
h6{
    font-weight: 400;
    letter-spacing:0;
    word-wrap: break-word;
    font-size: clamp( 16px, 1.25vw, 18px);
    line-height: 125%;
}
p{
    font-size: clamp( 16px, 1.25vw, 18px);
    line-height: 150%;
    margin-top: 0;
    font-weight: normal;
    letter-spacing: 0;
}