.site-header{
    position: absolute;
    width: 100%;
    max-width: 100vw;
    height: 70px;
    top: 0;
    left: 0;
    z-index: 99;

    @media screen and (max-width: 992px) {
        height: 50px;
    }

    &.fixed-header{
        width: 100%;
        position: fixed;
        transition: all 0.3s;
        animation: fadeDown 1s 1;
        background: rgba(0,0,0,0.4);
    }
    .header-content{
        height: 100%;
        width: 100%;
    }
    .navbar{
        background: transparent;
        padding: 0;
        height: 100%;
        justify-content: space-between;
        position: relative;
        .navbar-brand{
            width: 130px;
            height: 70px;
            padding: 0;
            margin: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
            @media screen and (max-width: 992px) {
                width: 100px;
                height: 50px;
            }
        }
        .navbar-nav{
            gap: 0;
            .nav-item{
                padding: 10px 0;
                position: relative;
                .nav-link{
                    color: #fff;
                    text-transform: uppercase;
                    padding: 0;
                    margin: 0 16px;
                    font-size: 12px;
                    font-weight: 400;
                    letter-spacing: 2px;
                    &:hover,&.active{
                        color: #e2bb53;
                    }
                    @media screen and (max-width: 568px) {
                        font-size: 16px;
                    }
                }
                .dropdown-menu{
                    position: absolute;
                    top: 60%;
                    background: #fff;
                    width: 250px;
                    margin: 15px 0 0;
                    padding: 15px 10px;
                    margin-left: -60px;
                    display: block;
                    zoom: 1;
                    opacity: 0;
                    position: absolute;
                    transform: translateY(10%);
                    transition: all .3s linear;
                    border-radius: 16px;
                    pointer-events: none;
                    .navbar-nav{
                        gap: 10px;
                        padding: 0;
                    }
                    .nav-item{
                        position: relative;
                        display: block;
                        .nav-link.sub-link{
                            color: #565656;
                            margin: 0;
                            padding: 8px 20px;
                            border-radius: 50px;
                            &:hover{
                                color: #fff;
                                background: #275c53;
                            }
                        }
                    }
                    &.show{
                        zoom: 1;
                        opacity: 1;
                        transform: translateY(0);
                        transition: all .3s linear;
                        z-index: 100;
                        pointer-events: auto;
                    }
                    @media screen and (max-width:992px) {
                        margin-left: 0;
                    }
                }
            }
        }

        .site-actions{
            display: flex;
            align-items: center;
            gap: 40px;
            margin-left: 20px;
            a{
                position: relative;
                font-size: 16px;
                color: #fff;
                &:first-child{
                    font-size: 20px;
                }
                &:hover{
                    color: #e2bb53;
                }
                .cart-count{
                    position: relative;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 50%;
                    background-clip: border-box;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    color: #e2bb53;
                    left: 0;
                    top: 1px;
                    margin-left: 10px;
                }
            }
        }
        .navbar-toggler{
            box-shadow: none;
            outline: none;
            &:focus{
                box-shadow: none;
                outline: none;
            }
            @media screen and (max-width: 992px) {
                font-size: 14px;
            }
        }
        @media screen and (max-width: 992px) {
            .navbar-menu{
                position: absolute;
                width: 100%;
                padding: 40px 30px;
                top: 100%;
                left: 0;
                background: rgba(0,0,0,0.9);
                z-index: 9;
                border-radius: 0 0 10px 10px;
                .nav-item .nav-link{
                    padding: 11px 0;
                }

                .site-actions{
                    margin-top: 30px;
                    width: 100%;
                    justify-content: center;
                    gap: 70px;
                }
            }
        }
    }
}

@keyframes fadeDown{
    0%{
        top: -100px;
    }
    100%{
        top: 0;
    }
}