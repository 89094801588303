.banner-section{
    width: 100%;
    height: auto;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 70px;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.5);
        z-index: 0;
    }
    .banner-content{
        position: relative;
        z-index: 1;
        .banner-text{
            width: 70vw;
            margin: auto;
            h2{
                font-size: clamp(26px, 4.5vw,88px);
                font-weight: 400;
                letter-spacing: 2px;
                text-decoration: underline;
                text-decoration-color: #d7b65d;
                text-decoration-thickness: 2px;
                line-height: 1.1em;
                overflow: visible;
                text-align: center;
            }
            @media screen and (max-width: 568px) {
                width: 90%;
            }
        }
    }

    background: url('https://thcclub.s3.amazonaws.com/static-data/heroImagesBannerData/HeroBannerImg_heroImage_2560x1600');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    @media screen and (max-width: 1620px) {
        background: url('https://thcclub.s3.amazonaws.com/static-data/heroImagesBannerData/HeroBannerImg_heroImage_1600x900');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
    }
    @media screen and (max-width: 1440px) {
        background: url('https://thcclub.s3.amazonaws.com//static-data/heroImagesBannerData/HeroBannerImg_heroImage_1366x768');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
    }
    @media screen and (max-width: 1280px) {
        background: url('https://thcclub.s3.amazonaws.com/static-data/heroImagesBannerData/HeroBannerImg_heroImage_1280x720');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
    }
    @media screen and (max-width: 1024px) {
        background: url('https://thcclub.s3.amazonaws.com/static-data/heroImagesBannerData/HeroBannerImg_heroImageTab_810x1080');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
    }
    @media screen and (max-width: 568px ){
        background-color: #fff;

        &::after{
            height: 50px;
        }
        img{
            width: 100%;
            object-fit: contain;
            object-position: top center;
        }

        background: url('https://thcclub.s3.amazonaws.com/static-data/heroImagesBannerData/HeroBannerImg_heroImageMob_824x1830');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
    }
    .banner-img{
        display: none;
        &._1{
            display: block;
        }
        @media screen and (max-width: 430px) and (max-height: 930px){
            &._1{
                display: block;
            }
        }
        @media screen and (max-width: 430px) and (max-height: 900px){
            &._1{
                display: none;
            }
            &._2{
                display: block;
            }
        }
        @media screen and (max-width: 400px) and (max-height: 870px){
            &._1{
                display: none;
            }
            &._2{
                display: none;
            }
            &._3{
                display: block;
            }
        }
        @media screen and (max-width: 400px) and (max-height: 845px){
            &._1{
                display: none;
            }
            &._2{
                display: none;
            }
            &._3{
                display: none;
            }
            &._4{
                display: block;
            }
        }
        @media screen and (max-width: 375px) and (max-height: 800px){
            &._1{
                display: none;
            }
            &._2{
                display: none;
            }
            &._3{
                display: none;
            }
            &._4{
                display: none;
            }
            &._5{
                display: block;
            }
        }
    }

}