.club-locations-page{
    .page-info-text,.club-locations, .map-con,.on-mobile-section{
        position: relative;
        z-index: 2;
    }
    .club-locations{
        padding: 50px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 60px;
    
        @media screen and (max-width: 568px) {
            padding: 0 20px;
            gap: 10px;
        }
        .location-item{
            width: calc(25% - 45px);
            padding: 10px;
            border: 1px solid #e2bb53;
            border-radius: 10px;
            .location-img{
                img{
                    width: 100%;
                }
            }
            h5{
                display: flex;
                align-items: center;
                gap: 20px;
                justify-content: center;
                margin-bottom: 20px;
                font-weight: 600;
                .title-icon{
                    width: 30px;
                    object-fit: contain;
                }
            }
            .detail-item{
                padding: 10px 5px;
                border-bottom: 1px solid #275c53;
    
                &:last-child{
                    border: 0;
                }
                a, h6, i{
                    font-size: clamp( 16px, 1.4vw, 20px);
                    color: #275c53;
                    font-weight: 500;
                }
                i{
                    color: #e2bb53;
                }
            }
            
            @media screen and (max-width: 768px) {
                width: calc(33.33% - 40px);
            }
            @media screen and (max-width: 568px) {
                width: 100%;
            }
        }
    }
    .on-mobile-section{
        .section-content{
            background: #275c53;
            padding: 60px 0 300px;
        }
        .truck-mg{
            position: relative;
            margin-top: -250px;
            text-align: center;
            img{
                width: 80%;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}