.order-details-page{
    .order-details-con{
        .order-table{
            width: 100%;
            margin: 20px 0 10px;
            .table-head{
                padding: 20px 25px;
                height: 70px;
                vertical-align: middle;
                background-color: #f5f0ea;
                color: #565656;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 1.5em;
                text-transform: none;
                text-align: center;
            }
            .table-data{
                padding: 20px 25px;
                height: calc(100% - 70px);
                vertical-align: middle;
                background-color: #fff;
                letter-spacing: 0;
                line-height: 1.5em;
                text-transform: none;
                text-align: start;
                border: 1px solid rgba(0,0,0,.1);
                border-top: 0;
                vertical-align: middle;
                font-size: 20px;
                color: #565656;
                font-weight: 500;
                &:nth-of-type(1){
                    width: 70%;
                }
                &:nth-of-type(2){
                    width: 30%;
                }
                .quantity{
                    font-weight: 700;
                }
                @media screen and (max-width:568px) {
                    padding: 15px 10px;
                }
            }
        }
    }
    .address-con{
        font-style: normal;
        margin-bottom: 0;
        border: 1px solid rgba(0,0,0,.1);
        border-bottom-width: 2px;
        border-right-width: 2px;
        text-align: left;
        width: 100%;
        border-radius: 5px;
        padding: 6px 12px;
    }
}