.products-page{
    .page-content{
        display: flex;
        padding-top: 100px;
        padding-bottom: 120px;
        flex-wrap: wrap;
        gap: 30px;
        @media screen and (max-width:568px) {
            padding-top: 60px;
            padding-bottom: 80px;
        }
        .shop-filters-con{
            width: calc(25% - 15px);
            @media screen and (max-width: 992px) {
                width: 100%;
            }
        }
        .shop-products-con{
            width: calc(75% - 15px);
            @media screen and (max-width: 992px) {
                width: 100%;
            }
            .shop-products-header{
                background-color: #f5f0ea;
                display: block;
                height: 64px;
                width: 100%;
                border-radius: 16px;
                background-clip: border-box;
                margin-bottom: 30px;
                @media screen and (max-width: 568px) {
                    height: 50px;
                }
                div{
                    font-size: 14px;
                    padding: 20px 30px;
                    margin-bottom: 0;
                    z-index: 2;
                    color: #565656;
                    @media screen and (max-width: 568px) {
                        padding:10px 15px;
                    }
                }
            }

            .shop-products{
                display: flex;
                flex-wrap: wrap;
                gap: 30px;

                .product-card{
                    width: calc(33.33% - 22px);
                    @media screen and (max-width: 1200px) {
                        width: calc(50% - 15px);
                    }
                    @media screen and (max-width: 992px) {
                        width: calc(33.33% - 22px);
                    }
                    @media screen and (max-width: 768px) {
                        width: calc(50% - 15px);
                    }
                    @media screen and (max-width: 568px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}