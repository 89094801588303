.reviews-con{
    .review{
        margin-top: 25px;
        .product-rating{
            display: flex;
            gap: 6px;
            font-size: 13px;
            align-items: center;
            justify-content: flex-start;
            color: #e2bb53;
            margin: 5px 0;
        }
    }
}
.review-form{
    .product-rating{
        display: flex;
        gap: 10px;
        font-size: 26px;
        align-items: center;
        justify-content: flex-start;
        color: #e2bb53;
        margin: 5px 0;
        cursor: pointer;
    }
}