.popular-products-section{
    margin-top: 80px;
    .products-con{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;
        .product-card{
            width: calc(25% - 23px);
            border: 1px solid #275c53;
            border-radius: 12px;
            padding: 0;
            overflow: hidden;
            @media screen and (max-width: 1080px) {
                width: calc(33% - 22px);
            }
            @media screen and (max-width: 768px) {
                width: calc(50% - 22px);
            }
            @media screen and (max-width: 568px) {
                width: 100%;
            }
            .card-title-con{
                background: #275c53;
                padding: 12px 8px;
                h2{
                    color: #fff;
                    margin: 0;
                }
            }
            .product-details{
                padding: 20px;
                height: auto;
                .product-price{
                    font-size: 40px;
                    margin: 20px 0;
                    color: #e2bb53;

                }
            }
        }
    }
} 