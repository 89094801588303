.map-container{
    z-index: 1 !important;
}
.location-name{
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    white-space: nowrap;
    text-transform: capitalize;
    padding: 2px 6px;
    border-radius: 6px;
    background: #275c53;
}
.location-item-details{
    width: 100%;
    h5{
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;
        margin-bottom: 20px;
        font-weight: 600;
        .title-icon{
            width: 30px;
            object-fit: contain;
        }
    }
    .detail-item{
        padding: 10px 5px;
        border-bottom: 1px solid #275c53;

        &:last-child{
            border: 0;
        }
        a, h6, i{
            font-size: clamp( 12px, 1.2vw, 18px);
            color: #275c53;
            font-weight: 500;
            text-decoration: none;
        }
        i{
            color: #e2bb53;
        }
    }
}

.gm-ui-hover-effect{
    top: 0 !important;
    right: 0 !important;
}