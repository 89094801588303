.order-placed-section{
    padding: 100px 0;
    .order-message{
        padding-top: 25px;
        padding-bottom: 25px;
        margin-bottom: 60px;
        font-size: 16px;
        margin: 20px auto 40px;
        text-align: center;
        background-color: #f5f0ea;
    }
    .order-table{
        width: 100%;
        margin-bottom: 32px;
        border-radius: 16px 16px 0 0;
        overflow: hidden;

        .table-head{
            padding: 20px 25px;
            height: 70px;
            vertical-align: middle;
            background-color: #f5f0ea;
            color: #565656;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 1.5em;
            text-transform: none;
            text-align: center;
        }
        .table-data{
            padding: 20px 25px;
            height: calc(100% - 70px);
            vertical-align: middle;
            background-color: #fff;
            color: #565656;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 1.5em;
            text-transform: none;
            text-align: center;
            border: 1px solid rgba(0,0,0,.1);
            border-top: 0;

            .product{
                display: flex;
                align-items: center;
                gap: 15px;
                .product-img{
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
            .product-quantity{
                width: 80%;
                height: 56px;
                max-width: 150px;
                margin: 0 auto;
            }
        }
        .table-row{
            .table-data{
                &:first-child{
                    width: 65px;
                    padding: 15px;
                    .table-head{
                        border-radius: 16px 0 0 0;
                    }
                    .remove-icon{
                        color: #e65338;
                        font-size: 32px;
                        line-height: 15px;
                        width: 16px;
                        height: 16px;
                        display: block;
                        text-align: center;
                        transition: all .2s ease;
                        font-weight: 400;
                        cursor: pointer;
                        margin: 0;
                    }
                }
            }
            &:nth-of-type(2){
                .table-data{
                    justify-content: flex-start;
                }
            }
        }
    }

    .address-con{
        font-style: normal;
        margin-bottom: 0;
        border: 1px solid rgba(0,0,0,.1);
        border-bottom-width: 2px;
        border-right-width: 2px;
        text-align: left;
        width: 100%;
        border-radius: 5px;
        padding: 6px 12px;
    }
}