.page-content{
    p, li{
        font-size: clamp( 20px, 1.52vw, 22px);
        margin-bottom: 12px;
        color: #565656;
    }
    ul{
        list-style: decimal;
    }
    h4{
        margin-bottom: 8px;
        margin-top: 22px;
        color: #275c53;
    }
}