.about-page{
    .page-content{
        padding: 60px 0;

        @media screen and (max-width: 768px){
            padding: 30px 0;
        }

        .team-section{
            position: relative;
            z-index: 1;
            .team-items{
                display: flex;
                flex-wrap: wrap;
                gap: 30px;
                .team-item{
                    width: calc(33.33% - 22px);
                    .item-img{
                        text-align: center;
                        img{
                            width: 90%;
                            object-fit: contain;
                            object-position: center;
                        }
                    }

                    @media screen and (max-width:680px) {
                        width: 100%;   
                    }
                }
                .item-detail{
                    margin-top: 30px;
                    text-align: center;
                    position: relative;
                    &::before{
                        content: '';
                        width: 60%;
                        height: 4px;
                        border-radius: 4px;
                        background: #e2bb53;
                        position: relative;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        margin-bottom: 30px;
                        display: block;
                    }
                    h4{
                        color: #275c53;
                        margin-bottom: 16px;
                        text-transform: capitalize;
                    }
                    h5{
                        color: #e2bb53;
                        text-transform: none;
                    }
                }
            }
        }

        .about-content-section{
            margin: 10px 0 40px;
            position: relative;
            z-index: 1;
            @media screen and (max-width: 768px) {
                padding: 0;
                margin-bottom: 0;
            }
            
            .section-img{
                @media screen and (max-width: 768px) {
                    padding: 0;
                }
                img{
                    border-radius: 16px;
                    overflow: hidden;
                }
            }
            .section-text{
                @media screen and (max-width: 768px) {
                    padding: 0;
                }
                h3{
                    color: #275c53;
                    margin-top: 16px;
                    margin-bottom: 10px;
                    .span{
                        color: #e2bb53;
                    }
                }
                p{
                    font-size: clamp( 16px, 1.52vw, 22px);
                }
            }
            .section-content{
                p{
                    color: #565656;
                }
            }
        }
        .about-banner{
            padding: 120px 60px;
            // background-image: url("../../assets//images/about-banner.jpg");
            // background-size: cover;
            // background-attachment: fixed ;
            // background-position: 50% 12px;
            transition: background 0s ;
            background-size: 100%;
            position: relative;
            background-color: #275c53;
            position: relative;
            z-index: 1;
            &::before{
                content: '';
                display: block;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                position: absolute;
                z-index: 0;
                pointer-events: none;
                opacity: 1;
                background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251609546065');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                @media screen and (max-width: 1920px){
                    background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251611263317');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                @media screen and (max-width: 1080px){
                    background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251612136741');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                @media screen and (max-width: 768px){
                    background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251612452520');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                @media screen and (max-width: 568px){
                    background-image: url('https://thcclub.s3.amazonaws.com/static-data/bg-patterns/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251613368960');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            h1,h2,h3,h4,h5,h6{
                text-align: center;
                color: #fff;
                position: relative;
                z-index: 2;
                margin: 0 auto;
                width: 70%;
                span{
                    color: #e2bb53;
                }
                @media screen and (max-width: 568px) {
                    width: 100%;
                }
            }
            h4{
                font-weight: 500;
                margin: 16px auto;
                text-align: center;
            }
            @media screen and (max-width: 568px) {
                padding: 80px 30px;
            }
        }
        .about-card-wrapper{
            padding: 30px 0;
        }
        .about-content-cards{
            position: relative;
            z-index: 1;

            .card-img-con{
                img{
                    width: 100%;
                    object-fit: contain;
                    object-position: center;
                    border-radius: 8px;
                }
            }
            .card-text-con{
                .card-section-title{
                    font-size: clamp(20px, 2.2vw, 32px);
                    font-weight: 700;
                    color: #275c53;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .sr-number{
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        background: #e2bb53;
                    }
                }
                p{
                    font-size: clamp(16px, 1.6vw, 22px);
                }
            }
        }
    }
}