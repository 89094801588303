.offers-section{
    padding: 60px 0;
    background: #F5F0EA;
    @media screen and (max-width: 568px) {
        padding: 0px 0 60px;
    }

    .section-carousel{
        .carousel-root {
            width: 100%;
            .carousel .slide {
              width: 100%;
              height: auto; 
            }
        
            .carousel {
                
                &.carousel-slider{
                    padding-bottom: 5px;
                }
                .control-dots{
                    bottom: 0;
                    outline: 0;
            
                    .dot{
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #CDCDCD;
                        opacity: 1;
                        margin: 0 2px;
                        box-shadow: none;
                        &.selected{
                            background: #275c53;
                        }
                    }
                }
        
                .slider-wrapper{
                    padding: 0;
                    .slide{
                        width: 100%;
                        padding: 30px 17px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @media screen and (max-width: 568px) {
                            padding: 20px 0;
                        }
                        .carousel-item{
                            width: auto;
                            height: auto;
                            max-width: 55vh;
                            margin: 0 auto;
                            display: block;
                            border-radius: 10px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}