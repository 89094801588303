.confirm-account-page{
    padding-top: 100px;
    .confirmaiton-message-con{
        border: 1px solid #d3ced2;
        padding: 30px;
        margin: 2em 0;
        text-align: left;
        border-radius: 5px;
        width: 90vw;
        max-width: 800px;
        margin: 100px auto;
        display: flex;
        flex-direction: column;
        gap: 50px;
        align-items: center;
        justify-content: center;
    }
}