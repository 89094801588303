.location-content-section{
    padding: 30px 0;
    .location-content{
        position: relative;
        z-index: 2;
    }
    .detail-item{
        padding: 20px 5px;
        border-bottom: 1px solid #275c53;
    
        &:last-child{
            border: 0;
        }
        a, h6, i{
            font-size: clamp( 16px, 1.4vw, 20px);
            color: #275c53;
            font-weight: 500;
            text-decoration: none;
        }
        i{
            color: #e2bb53;
        }
    }
}

.related-coa-section{
    position: relative;
    z-index: 2;
    .coa-items{
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        .coa-item{
            width: calc(33.33% - 27px);
            font-size: 18px;
            font-weight: 700;
        }
    }
}