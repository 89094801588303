.blog-page{
    .blog-content-con{
        padding: 60px 0;
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        align-items: flex-start;
        position: relative;
        z-index: 2;
        
        @media screen and (max-width: 992px) {
            flex-direction: column-reverse;
        }
        @media screen and (max-width: 568px) {
            padding: 30px 0;
        }
        .blog-posts-con{
            width: calc(70% - 20px);
            display: flex;
            gap: 30px;
            flex-wrap: wrap;

            @media screen and (max-width: 992px) {
                width: 100%;
            }
        }
        .blog-sidebar-con{
            width: calc(30% - 20px);
            @media screen and (max-width: 992px) {
                width: 100%;
            }
        }
        
    }
}