.testimonisals-section{
    padding: 90px 0;
    @media screen and (max-width: 568px) {
        padding: 60px 0 20px;
    }

    .section-content{
        margin-top: 54px;
        @media screen and (max-width: 568px) {
            margin-top: 20px;
        }
    }
    .carousel-root {
        width: 100%;
        .carousel .slide {
          width: 100%;
          height: auto; 
        }
    
        .carousel {
            
            &.carousel-slider{
                padding-bottom: 50px;
            }
            .control-dots{
                bottom: 0;
                outline: 0;
        
                .dot{
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #CDCDCD;
                    opacity: 1;
                    margin: 0 2px;
                    box-shadow: none;
                    &.selected{
                        background: #275c53;
                    }
                }
            }
    
            .slider-wrapper{
                padding: 0;
                .slide{
                    width: 100%;
                    padding: 30px 17px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: 568px) {
                        padding: 20px 0;
                    }
                    .carousel-item{
                        width: 80%;
                        max-width: 1020px;
                        margin: 0 auto;
                        width: 100%;
                        display: block;
                        .item-content{
                            width: 100%;
                            min-height: 150px; 
                            background-color: #f5f0ea;
                            margin-bottom: 36px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 2;
                            position: relative;
                            border-radius: 16px;
                            background-clip: border-box;
                            padding: 54px 20px;
                            position: relative;
                            &::before{
                                content: '';
                                position: absolute;
                                width: 60px;
                                height: 65px;
                                background: url(../../../assets/images/quote.png);
                                background-repeat: no-repeat;
                                background-size: contain;
                                background-position: top center;
                                top: -25px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                            h4{                   
                                font-style: italic;
                                font-weight: 400;
                                font-size: 24px;
                                line-height: 125%;
                                letter-spacing: 0.2px;
                                color: #565656;
                                @media screen and (max-width: 568px){
                                    font-size: 18px;
                                }
                            }

                        }
                        .item-info{
                            h3{
                                font-size: 24px;
                                margin: 4px auto 0px;
                                position: relative;
                                color: #275c53;
                            }
                            h6{
                                font-size: 16px;
                                margin: 4px auto 0px;
                                position: relative;
                                color: #e2bb53;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}