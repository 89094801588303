.page-banner{
    padding-top: 80px;
    display: flex;
    min-height: 240px;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
    background: url('https://thcclub.s3.amazonaws.com/static-data/banner-backgrounds/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251629466533');
    background-size: cover;
    background-position: bottom left;
    background-repeat: no-repeat;
    @media screen and (max-width: 1920px) {
        background: url('https://thcclub.s3.amazonaws.com/static-data/banner-backgrounds/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251630206000');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
    }
    @media screen and (max-width: 1560px) {
        background: url('https://thcclub.s3.amazonaws.com/static-data/banner-backgrounds/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251631093264');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
    }
    @media screen and (max-width: 1100px) {
        background: url('https://thcclub.s3.amazonaws.com/static-data/banner-backgrounds/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251632072974');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
    }
    @media screen and (max-width: 768px) {
        background: url('https://thcclub.s3.amazonaws.com/static-data/banner-backgrounds/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202401251632508239');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    @media screen and (max-width: 568px) {
        min-height: 200px;
        padding-top: 50px;
    }
    &::after{
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        left: 0;
        top: 0;
        position: absolute;
        z-index: 0;
    }
    .banner-content{
        position: relative;
        z-index: 2;
    }
    h2{
        font-size: 48px;
        margin: 0;
        position: relative;
        z-index: 2;
        @media screen and (max-width: 768px) {
            font-size: 38px;
        }
        @media screen and (max-width: 568px) {
            font-size: 28px;
        }
    }
}